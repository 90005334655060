.header{
    display: flex;
    width: 100%;
    height:40vh;
    justify-content: center;
    align-items: center;
}

.logo{
    width: 100%;
    height: 100%;
    background-image: url("../../public/logo.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

@media screen and (max-width: 768px) {
    .header{
        height: 25vh;
    }
    
}