.menu-div{
    height: 10vh;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    justify-items: center;
}

.element-div{
    text-decoration: none;
    color: black;
    text-transform: uppercase;
    font-weight: 700;
}

.contact{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.header-menu{
    font-size: 30px;
}
.content-menu{
    text-align: center;
    font-weight: 500;
}

a{
    text-decoration: none;
    color: green;
}

.mapa{
    width: 100%;
    height: 40vh;
    background-image: url("../../public/mapa.jpeg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 40px;
}

@media screen and (max-width: 768px) {
    .menu-div{
        grid-template-columns: repeat(1, 1fr);
        gap: 20px;
        margin-bottom: 50px;
    }

    .header-menu{
        margin:0;
    }

    .content-menu{
        margin: 0;
    }
    
    .mapa{
        height: 30vh;
    }
}